<template>
  <div class="flex flex-col bg-white pb-0 lg:pb-10">
    <!--------------------------------------VERSION DESKTOP----------------------------------------------->
    <div class="hidden lg:flex flex-col gap-y-5">
      <!-- Title -->
      <div class="flex flex-col">
        <h1 class="text-2xl font-EffraR text-black">
          <span v-if="$route.params.acte">{{ acte.name || "--" }} </span>
          {{
            $route.params.city != "undefined" ? "à " + $route.params.city : ""
          }}
          - Prenez rendez-vous chez un spécialiste
        </h1>
        <p class="text-base font-EffraR pt-3" v-html="acte.description"></p>
        <p class="text-lg font-EffraR pt-3">
          {{ lengthData ? `${lengthData} résultats trouvés` : "" }}
        </p>
      </div>
      <!-- Filters -->
      <div class="flex flex-row gap-x-4" v-if="false">
        <!--- Disponibilités --->
        <button
          class="
            relative
            dropdown:block
            bg-white
            h-10
            flex
            items-center
            justify-center
            gap-x-2
            border border-solid border-gray-300
            rounded-cu
            px-2
            cursor-pointer
            focus:border-rosDok-ultra
          "
          role="navigation"
          aria-haspopup="true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
          >
            <path
              stroke="#111"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 16.5c4.142 0 7.5-3.358 7.5-7.5 0-4.142-3.358-7.5-7.5-7.5-4.142 0-7.5 3.358-7.5 7.5 0 4.142 3.358 7.5 7.5 7.5z"
            />
            <path
              stroke="#111"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 4.5V9l3 1.5"
            />
          </svg>
          <span class="font-EffraR text-lg">Disponibilités</span>

          <!-- Content -->

          <ul
            class="hidden absolute left-0 box-dropdown min-w-full xl:min-w-200"
            aria-label="submenu"
            style="top: 25px"
          >
            <li class="flex items-center gap-x-2 mb-4">
              <input
                type="radio"
                class="
                  form-radio
                  text-rosDok-ultra
                  border-2 border-solid border-rosDok-ultra
                  focus:outline-none focus:shadow-none
                  text-xl
                "
                value="today"
                v-model="filters.dispo"
                id="today"
              />
              <label for="today" class="font-EffraR text-lg text-black"
                >Today</label
              >
            </li>
            <li class="flex items-center gap-x-2">
              <input
                type="radio"
                class="
                  form-radio
                  text-rosDok-ultra
                  border-2 border-solid border-rosDok-ultra
                  focus:outline-none focus:shadow-none
                  text-xl
                "
                value="week"
                v-model="filters.dispo"
                id="week"
              />
              <label for="week" class="font-EffraR text-lg text-black">
                This week</label
              >
            </li>
          </ul>
        </button>
        <!--- Prix de consultation --->
        <button
          class="
            relative
            dropdown:block
            bg-white
            h-10
            flex
            items-center
            justify-center
            gap-x-2
            border border-solid border-gray-300
            rounded-cu
            px-2
            cursor-pointer
            focus:border-rosDok-ultra
          "
          role="navigation"
          aria-haspopup="true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
          >
            <g clip-path="url(#clip0)">
              <path
                stroke="#111"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 .75v16.5M12.75 3.75H7.125c-.696 0-1.364.277-1.856.769S4.5 5.679 4.5 6.375c0 .696.277 1.364.769 1.856S6.429 9 7.125 9h3.75c.696 0 1.364.277 1.856.769s.769 1.16.769 1.856c0 .696-.277 1.364-.769 1.856s-1.16.769-1.856.769H4.5"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <path fill="#fff" d="M0 0H18V18H0z" />
              </clipPath>
            </defs>
          </svg>

          <span class="font-EffraR text-lg">Prix de consultation</span>

          <!-- Content -->

          <ul
            class="hidden absolute left-0 box-dropdown min-w-full xl:min-w-370"
            aria-label="submenu"
            style="top: 25px"
          >
            <div class="flex flex-col gap-y-8">
              <p class="text-sm font-EffraR text-left" style="color: #767676">
                The average price is MAD 250
              </p>

              <VueSlider
                v-model="rangeValue"
                :max="3000"
                tooltip="none"
                :tooltip-formatter="'{rangeValue}DH'"
                :process-style="{ backgroundColor: '#cb4953' }"
                :tooltip-style="{
                  backgroundColor: '#cb4953',
                  borderColor: '#cb4953',
                }"
              >
                <template v-slot:dot="{ rangeValue, focus }">
                  <div
                    :class="['custom-dot cursor-pointer', { focus }]"
                  ></div> </template
              ></VueSlider>

              <div class="flex justify-between w-full">
                <div class="flex flex-col gap-y-1">
                  <label
                    for="minprice"
                    class="font-EffraR text-base text-gray-600"
                    >Min Price</label
                  >
                  <div class="relative">
                    <input
                      type="number"
                      placeholder="Min Price"
                      class="
                        pl-11
                        border-2 border-solid
                        h-10
                        flex
                        justify-center
                        px-2
                        rounded-cu
                        font-EffraR
                        text-base text-black
                        max-w-100
                      "
                      style="border-color: #e4e4e4"
                      v-model="filters.range[0]"
                    />
                    <span
                      class="
                        absolute
                        left-0
                        top-0
                        h-full
                        flex
                        justify-center
                        items-center
                        font-EffraR
                        text-base text-black
                        px-2
                      "
                      >MAD</span
                    >
                  </div>
                </div>
                <div class="flex flex-col">
                  <label
                    for="minprice"
                    class="font-EffraR text-base text-gray-600"
                    >Max Price</label
                  >
                  <div class="relative">
                    <input
                      type="number"
                      placeholder="Max Price"
                      class="
                        pl-11
                        border-2 border-solid
                        h-10
                        flex
                        justify-center
                        px-2
                        rounded-cu
                        font-EffraR
                        text-base text-black
                        max-w-100
                      "
                      style="border-color: #e4e4e4"
                      v-model="filters.range[1]"
                    />
                    <span
                      class="
                        absolute
                        left-0
                        top-0
                        h-full
                        flex
                        justify-center
                        items-center
                        font-EffraR
                        text-base text-black
                        px-2
                      "
                      >MAD</span
                    >
                  </div>
                </div>
              </div>

              <div class="flex justify-between">
                <button
                  class="
                    border-0
                    h-10
                    flex
                    justify-center
                    items-center
                    font-EffraR
                    text-gray-500
                    bg-transparent
                    text-lg
                    px-3
                    rounded
                    cursor-pointer
                  "
                >
                  Clear
                </button>
                <button
                  class="
                    border-0
                    h-10
                    flex
                    justify-center
                    items-center
                    font-EffraR
                    text-white
                    bg-rosDok-ultra
                    text-lg
                    px-3
                    rounded
                    cursor-pointer
                  "
                >
                  Save
                </button>
              </div>
            </div>
          </ul>
        </button>
        <!--- Langues Parlées  --->
        <button
          class="
            relative
            dropdown:block
            bg-white
            h-10
            flex
            items-center
            justify-center
            gap-x-2
            border border-solid border-gray-300
            rounded-cu
            px-2
            cursor-pointer
            focus:border-rosDok-ultra
          "
          role="navigation"
          aria-haspopup="true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
          >
            <path
              stroke="#111"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 14.667c3.682 0 6.667-2.985 6.667-6.667S11.682 1.333 8 1.333 1.333 4.318 1.333 8 4.318 14.667 8 14.667zM1.333 8h13.334"
            />
            <path
              stroke="#111"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 1.333C9.667 3.16 10.615 5.528 10.667 8c-.052 2.472-1 4.841-2.667 6.667C6.332 12.84 5.385 10.472 5.333 8c.052-2.472 1-4.841 2.667-6.667v0z"
            />
          </svg>

          <span class="font-EffraR text-lg">Langues Parlées</span>

          <!-- Content -->

          <ul
            class="hidden absolute left-0 box-dropdown min-w-full xl:min-w-200"
            aria-label="submenu"
            style="top: 25px"
          >
            <li class="flex items-center gap-x-2 mb-4">
              <input
                type="checkbox"
                class="
                  form-checkbox
                  text-rosDok-ultra
                  border-2 border-solid border-gray-400
                  focus:outline-none focus:shadow-none
                  text-xl
                "
                value="Arabe"
                v-model="filters.langs"
                id="arabe"
              />
              <label for="arabe" class="font-EffraR text-lg text-black"
                >Arabe</label
              >
            </li>
            <li class="flex items-center gap-x-2 mb-4">
              <input
                type="checkbox"
                class="
                  form-checkbox
                  text-rosDok-ultra
                  border-2 border-solid border-gray-400
                  focus:outline-none focus:shadow-none
                  text-xl
                "
                value="Francais"
                v-model="filters.langs"
                id="francais"
              />
              <label for="francais" class="font-EffraR text-lg text-black"
                >Francais</label
              >
            </li>
            <li class="flex items-center gap-x-2 mb-4">
              <input
                type="checkbox"
                class="
                  form-checkbox
                  text-rosDok-ultra
                  border-2 border-solid border-gray-400
                  focus:outline-none focus:shadow-none
                  text-xl
                "
                value="Amazigh"
                v-model="filters.langs"
                id="tachelhit"
              />
              <label for="tachelhit" class="font-EffraR text-lg text-black"
                >Tachelhit</label
              >
            </li>
            <li class="flex items-center gap-x-2 mb-4">
              <input
                type="checkbox"
                class="
                  form-checkbox
                  text-rosDok-ultra
                  border-2 border-solid border-gray-400
                  focus:outline-none focus:shadow-none
                  text-xl
                "
                value="English"
                v-model="filters.langs"
                id="anglais"
              />
              <label for="anglais" class="font-EffraR text-lg text-black"
                >Anglais</label
              >
            </li>
            <li class="flex items-center gap-x-2 mb-4">
              <input
                type="checkbox"
                class="
                  form-checkbox
                  text-rosDok-ultra
                  border-2 border-solid border-gray-400
                  focus:outline-none focus:shadow-none
                  text-xl
                "
                value="espagnol"
                v-model="filters.dispo"
                id="espagnol"
              />
              <label for="espagnol" class="font-EffraR text-lg text-black"
                >Espagnol</label
              >
            </li>
          </ul>
        </button>
        <!--- More Filters  --->
        <button
          class="
            relative
            dropdown:block
            bg-white
            h-10
            flex
            items-center
            justify-center
            gap-x-2
            border border-solid border-gray-300
            rounded-cu
            px-2
            cursor-pointer
            focus:border-rosDok-ultra
          "
          role="navigation"
          aria-haspopup="true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
          >
            <path
              stroke="#111"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M14.667 2H1.333l5.334 6.307v4.36L9.333 14V8.307L14.667 2z"
            />
          </svg>

          <span class="font-EffraR text-lg">Plus de filtres</span>

          <!-- Content -->

          <ul
            class="hidden absolute left-0 box-dropdown min-w-full xl:min-w-500"
            aria-label="submenu"
            style="top: 25px"
          >
            <h1
              class="font-EffraR w-full text-2xl text-black text-center mb-12"
            >
              Plus de filtres
            </h1>

            <div class="flex flex-col gap-y-8">
              <div class="flex flex-col w-full gap-y-2">
                <label class="text-lg font-EffraM text-black text-left"
                  >Sexe</label
                >
                <div class="grid gap-4 grid-cols-2 w-full">
                  <div class="flex items-center gap-x-2">
                    <input
                      type="checkbox"
                      class="
                        form-checkbox
                        text-rosDok-ultra
                        border-2 border-solid border-gray-400
                        focus:outline-none focus:shadow-none
                        text-xl
                      "
                      value="homme"
                      v-model="filters.gender"
                      id="homme"
                    />
                    <label for="homme" class="font-EffraR text-lg text-black"
                      >Homme</label
                    >
                  </div>
                  <div class="flex items-center gap-x-2">
                    <input
                      type="checkbox"
                      class="
                        form-checkbox
                        text-rosDok-ultra
                        border-2 border-solid border-gray-400
                        focus:outline-none focus:shadow-none
                        text-xl
                      "
                      value="femme"
                      v-model="filters.gender"
                      id="femme"
                    />
                    <label for="femme" class="font-EffraR text-lg text-black"
                      >Femme</label
                    >
                  </div>
                </div>
              </div>

              <div class="flex flex-col w-full gap-y-2">
                <label class="text-lg font-EffraM text-black text-left"
                  >Type de consultation</label
                >
                <div class="grid gap-4 grid-cols-2 w-full">
                  <div class="flex items-center gap-x-2">
                    <input
                      type="checkbox"
                      class="
                        form-checkbox
                        text-rosDok-ultra
                        border-2 border-solid border-gray-400
                        focus:outline-none focus:shadow-none
                        text-xl
                      "
                      value="consultationcabinet"
                      v-model="filters.type"
                      id="consultationcabinet"
                    />
                    <label
                      for="consultationcabinet"
                      class="font-EffraR text-lg text-black"
                      >Consultation au cabinet</label
                    >
                  </div>
                  <div class="flex items-center gap-x-2">
                    <input
                      type="checkbox"
                      class="
                        form-checkbox
                        text-rosDok-ultra
                        border-2 border-solid border-gray-400
                        focus:outline-none focus:shadow-none
                        text-xl
                      "
                      value="teleconsultation"
                      v-model="filters.sex"
                      id="teleconsultation"
                    />
                    <label
                      for="teleconsultation"
                      class="font-EffraR text-lg text-black"
                      >Téléconsultation</label
                    >
                  </div>
                </div>
              </div>

              <div class="flex justify-between">
                <button
                  class="
                    border-0
                    h-10
                    flex
                    justify-center
                    items-center
                    font-EffraR
                    text-gray-500
                    bg-transparent
                    text-lg
                    px-3
                    rounded
                    cursor-pointer
                  "
                >
                  Clear
                </button>
                <button
                  class="
                    border-0
                    h-10
                    flex
                    justify-center
                    items-center
                    font-EffraR
                    text-white
                    bg-rosDok-ultra
                    text-lg
                    px-3
                    rounded
                    cursor-pointer
                  "
                >
                  23 results found
                </button>
              </div>
            </div>
          </ul>
        </button>
      </div>
    </div>
    <!--------------------------------------END VERSION DESKTOP----------------------------------------------->

    <!--------------------------------------VERSION MOBILE----------------------------------------------->
    <div class="block lg:hidden">
      <div id="mapAndFilter" class="relative">
        <map-based-algolia
          class="w-full"
          :state="{
            items: {
              ...mapDoctors,
            },
          }"
        />
        <div
          class="
            static
            md:absolute
            top-0
            inset-x-0
            py-6
            hidden
            md:justify-center
          "
        >
          <div class="relative mx-3 w-full">
            <div
              class="
                flex flex-col
                md:flex-row md:items-center
                gap-y-2
                md:gap-y-0
                gap-x-0
                md:gap-x-4
                bg-white
                px-5
                py-2
                rounded-t
                mw-full
                md:min-w-370
              "
            >
              <div class="relative">
                <dropDownAlgolia :value.sync="cityName" />
              </div>
              <div class="flex flex-row gap-x-4 w-full">
                <div class="relative w-full">
                  <input
                    class="
                      h-12
                      rounded
                      border border-solid
                      pl-10
                      pr-2
                      font-EffraR
                      text-base text-black
                      w-full
                    "
                    style="border-color: #e4e4e4"
                    placeholder="(Nom, Specialité)"
                    v-model="search.query"
                  />
                  <span
                    class="absolute left-0 top-0 h-full flex items-center px-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#ABABAB"
                        d="M15.026 13.847l3.569 3.569-1.18 1.179-3.567-3.57c-1.328 1.065-2.98 1.644-4.681 1.641-4.14 0-7.5-3.36-7.5-7.5 0-4.14 3.36-7.5 7.5-7.5 4.14 0 7.5 3.36 7.5 7.5.002 1.702-.577 3.354-1.641 4.681zm-1.672-.618c1.058-1.088 1.648-2.545 1.646-4.063 0-3.223-2.61-5.833-5.833-5.833-3.224 0-5.834 2.61-5.834 5.833C3.333 12.39 5.943 15 9.167 15c1.517.002 2.975-.588 4.062-1.646l.125-.125z"
                      />
                    </svg>
                  </span>
                </div>

                <button
                  class="
                    bg-transparent
                    h-12
                    px-4
                    cursor-pointer
                    rounded
                    flex
                    items-center
                    gap-x-2
                  "
                  style="border: solid 1px #e4e4e4"
                  @click="mobileFilters = !mobileFilters"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="#ABABAB"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.6"
                      d="M3.333 17.5v-5.833M3.333 8.333V2.5M10 17.5V10M10 6.667V2.5M16.667 17.5v-4.167M16.667 10V2.5M.833 11.667h5M7.5 6.667h5M14.167 13.333h5"
                    />
                  </svg>
                  <span class="font-EffraR hidden md:block">Filtres</span>
                </button>
              </div>
            </div>
            <div
              v-if="mobileFilters"
              class="
                absolute
                z-10
                mt-0
                bg-white
                rounded-b
                shadow
                overflow-auto
                max-h-auto
                w-full
                overflow-y-scroll
              "
            >
              <section
                id="Disponibilités"
                class="
                  flex flex-col
                  gap-y-4
                  border-0 border-t border-solid border-gray-400
                  px-5
                  py-5
                "
              >
                <h1 class="font-EffraH text-lg text-black">Disponibilités</h1>
                <div class="flex items-center gap-x-2">
                  <input
                    type="radio"
                    class="
                      form-radio
                      text-rosDok-ultra
                      border-2 border-solid border-rosDok-ultra
                      focus:outline-none focus:shadow-none
                      text-xl
                    "
                    value="today"
                    v-model="filters.dispo"
                    id="today"
                  />
                  <label for="today" class="font-EffraR text-lg text-black"
                    >Today</label
                  >
                </div>
                <div class="flex items-center gap-x-2">
                  <input
                    type="radio"
                    class="
                      form-radio
                      text-rosDok-ultra
                      border-2 border-solid border-rosDok-ultra
                      focus:outline-none focus:shadow-none
                      text-xl
                    "
                    value="week"
                    v-model="filters.dispo"
                    id="week"
                  />
                  <label for="week" class="font-EffraR text-lg text-black">
                    This week</label
                  >
                </div>
              </section>
              <section
                id="Prix"
                class="
                  flex flex-col
                  gap-y-4
                  border-0 border-t border-solid border-gray-400
                  px-5
                  py-5
                "
              >
                <h1 class="font-EffraH text-lg text-black">
                  Prix de consultation
                </h1>

                <p class="text-sm font-EffraR text-left" style="color: #767676">
                  The average price is MAD 250
                </p>

                <VueSlider
                  v-model="rangeValue"
                  :max="3000"
                  tooltip="none"
                  :tooltip-formatter="'{rangeValue}DH'"
                  :process-style="{ backgroundColor: '#cb4953' }"
                  :tooltip-style="{
                    backgroundColor: '#cb4953',
                    borderColor: '#cb4953',
                  }"
                >
                  <template v-slot:dot="{ rangeValue, focus }">
                    <div
                      :class="['custom-dot cursor-pointer', { focus }]"
                    ></div> </template
                ></VueSlider>

                <div class="flex justify-between w-full">
                  <div class="flex flex-col gap-y-1">
                    <label
                      for="minprice"
                      class="font-EffraR text-base text-gray-600"
                      >Min Price</label
                    >
                    <div class="relative">
                      <input
                        type="number"
                        placeholder="Min Price"
                        class="
                          pl-11
                          border-2 border-solid
                          h-10
                          flex
                          justify-center
                          px-2
                          rounded-cu
                          font-EffraR
                          text-base text-black
                          max-w-100
                        "
                        style="border-color: #e4e4e4"
                        v-model="filters.range[0]"
                      />
                      <span
                        class="
                          absolute
                          left-0
                          top-0
                          h-full
                          flex
                          justify-center
                          items-center
                          font-EffraR
                          text-base text-black
                          px-2
                        "
                        >MAD</span
                      >
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <label
                      for="minprice"
                      class="font-EffraR text-base text-gray-600"
                      >Max Price</label
                    >
                    <div class="relative">
                      <input
                        type="number"
                        placeholder="Max Price"
                        class="
                          pl-11
                          border-2 border-solid
                          h-10
                          flex
                          justify-center
                          px-2
                          rounded-cu
                          font-EffraR
                          text-base text-black
                          max-w-100
                        "
                        style="border-color: #e4e4e4"
                        v-model="filters.range[1]"
                      />
                      <span
                        class="
                          absolute
                          left-0
                          top-0
                          h-full
                          flex
                          justify-center
                          items-center
                          font-EffraR
                          text-base text-black
                          px-2
                        "
                        >MAD</span
                      >
                    </div>
                  </div>
                </div>
              </section>
              <section
                id="Langues"
                class="
                  flex flex-col
                  gap-y-4
                  border-0 border-t border-solid border-gray-400
                  px-5
                  py-5
                "
              >
                <h1 class="font-EffraH text-lg text-black">Langues Parlées</h1>
                <ul class="grid gap-4 grid-cols-2">
                  <li class="flex items-center gap-x-2 mb-4">
                    <input
                      type="checkbox"
                      class="
                        form-checkbox
                        text-rosDok-ultra
                        border-2 border-solid border-gray-400
                        focus:outline-none focus:shadow-none
                        text-xl
                      "
                      value="Arabe"
                      v-model="filters.langs"
                      id="arabe"
                    />
                    <label for="arabe" class="font-EffraR text-lg text-black"
                      >Arabe</label
                    >
                  </li>
                  <li class="flex items-center gap-x-2 mb-4">
                    <input
                      type="checkbox"
                      class="
                        form-checkbox
                        text-rosDok-ultra
                        border-2 border-solid border-gray-400
                        focus:outline-none focus:shadow-none
                        text-xl
                      "
                      value="Francais"
                      v-model="filters.langs"
                      id="francais"
                    />
                    <label for="francais" class="font-EffraR text-lg text-black"
                      >Francais</label
                    >
                  </li>
                  <li class="flex items-center gap-x-2 mb-4">
                    <input
                      type="checkbox"
                      class="
                        form-checkbox
                        text-rosDok-ultra
                        border-2 border-solid border-gray-400
                        focus:outline-none focus:shadow-none
                        text-xl
                      "
                      value="Amazigh"
                      v-model="filters.langs"
                      id="tachelhit"
                    />
                    <label
                      for="tachelhit"
                      class="font-EffraR text-lg text-black"
                      >Tachelhit</label
                    >
                  </li>
                  <li class="flex items-center gap-x-2 mb-4">
                    <input
                      type="checkbox"
                      class="
                        form-checkbox
                        text-rosDok-ultra
                        border-2 border-solid border-gray-400
                        focus:outline-none focus:shadow-none
                        text-xl
                      "
                      value="English"
                      v-model="filters.langs"
                      id="anglais"
                    />
                    <label for="anglais" class="font-EffraR text-lg text-black"
                      >Anglais</label
                    >
                  </li>
                  <li class="flex items-center gap-x-2 mb-4">
                    <input
                      type="checkbox"
                      class="
                        form-checkbox
                        text-rosDok-ultra
                        border-2 border-solid border-gray-400
                        focus:outline-none focus:shadow-none
                        text-xl
                      "
                      value="espagnol"
                      v-model="filters.dispo"
                      id="espagnol"
                    />
                    <label for="espagnol" class="font-EffraR text-lg text-black"
                      >Espagnol</label
                    >
                  </li>
                </ul>
              </section>
              <section
                id="sexe"
                class="
                  flex flex-col
                  gap-y-4
                  border-0 border-t border-solid border-gray-400
                  px-5
                  py-5
                "
              >
                <h1 class="font-EffraH text-lg text-black">Sexe</h1>
                <div class="grid gap-4 grid-cols-2">
                  <div class="flex items-center gap-x-2">
                    <input
                      type="checkbox"
                      class="
                        form-checkbox
                        text-rosDok-ultra
                        border-2 border-solid border-gray-400
                        focus:outline-none focus:shadow-none
                        text-xl
                      "
                      value="homme"
                      v-model="filters.sex"
                      id="homme"
                    />
                    <label for="homme" class="font-EffraR text-lg text-black"
                      >Homme</label
                    >
                  </div>
                  <div class="flex items-center gap-x-2">
                    <input
                      type="checkbox"
                      class="
                        form-checkbox
                        text-rosDok-ultra
                        border-2 border-solid border-gray-400
                        focus:outline-none focus:shadow-none
                        text-xl
                      "
                      value="femme"
                      v-model="filters.sex"
                      id="femme"
                    />
                    <label for="femme" class="font-EffraR text-lg text-black"
                      >Femme</label
                    >
                  </div>
                </div>
              </section>
              <section
                id="motifFilter"
                class="
                  flex flex-col
                  gap-y-4
                  border-0 border-t border-solid border-gray-400
                  px-5
                  py-5
                "
              >
                <h1 class="font-EffraH text-lg text-black">
                  Motifs de consultation
                </h1>
                <div class="grid gap-4 grid-cols-2 w-full">
                  <div class="flex items-center gap-x-2">
                    <input
                      type="checkbox"
                      class="
                        form-checkbox
                        text-rosDok-ultra
                        border-2 border-solid border-gray-400
                        focus:outline-none focus:shadow-none
                        text-xl
                      "
                      value="consultationcabinet"
                      v-model="filters.sex"
                      id="consultationcabinet"
                    />
                    <label
                      for="consultationcabinet"
                      class="font-EffraR text-lg text-black"
                      >En cabinet</label
                    >
                  </div>
                  <div class="flex items-center gap-x-2">
                    <input
                      type="checkbox"
                      class="
                        form-checkbox
                        text-rosDok-ultra
                        border-2 border-solid border-gray-400
                        focus:outline-none focus:shadow-none
                        text-xl
                      "
                      value="teleconsultation"
                      v-model="filters.sex"
                      id="teleconsultation"
                    />
                    <label
                      for="teleconsultation"
                      class="font-EffraR text-lg text-black"
                      >Téléconsultation</label
                    >
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col px-8 py-4">
        <h1 class="text-2xl font-EffraR text-black">
          <span class="capitalize">{{ $route.params.query || "--" }}</span>
          <span>{{
            $route.params.city ? ` à ${$route.params.city}` : ""
          }}</span>
        </h1>
        <p class="text-lg font-EffraR">
          {{ lengthData ? `${lengthData} Résultats` : "" }}
        </p>
      </div>
    </div>
    <!--------------------------------------END VERSION MOBILE----------------------------------------------->
  </div>
</template>

<script>
  import VueSlider from "vue-slider-component";
  import "vue-slider-component/theme/default.css";

  // Algolia Map
  const mapBasedAlgolia = () => import("../../map");

  export default {
    props: {
      lengthData: {
        type: Number,
        required: false,
      },
      mapDoctors: {
        type: Array,
        required: true,
      },
      acte: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        mobileFilters: false,
        filters: {
          range: [20, 3000],
          dispo: null,
          langs: [],
          sex: [],
        },
        search: {
          citySelect: "",
          query: null,
          acte: null,
        },
      };
    },
    watch: {
      "filters.langs": function (newVal, oldVal) {
        this.$router.push({
          name: "doctorsList",
          params: {
            ...this.$route.query,
            languages: newVal,
          },
        });
      },
      deep: true,
    },
    async mounted() {
      if (this.$route.params.city) {
        this.search.citySelect = this.$route.params.city;
      }

      if (this.$route.params.acte) {
        this.search.acte = this.$route.params.acte;
      }
    },
    computed: {
      cityName: {
        get() {
          return this.search.citySelect;
        },
        set(value) {
          this.search.citySelect = value;
        },
      },
      rangeValue: {
        get() {
          return this.filters.range;
        },
        set(value) {
          this.filters.range = value;
        },
      },
    },
    components: { VueSlider, mapBasedAlgolia },
  };
</script>

<style>
.custom-dot {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fd5b68;
  transition: all 0.3s;
}
.custom-dot:hover {
  transform: rotateZ(45deg);
}
.custom-dot.focus {
  border-radius: 50%;
}
.box-dropdown {
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
  border: solid 1px #e4e4e4;
  background-color: #ffffff;
}
</style>
